<template>
    <div class="register">

        <h2>Erstelle deine Zugangsdaten.</h2>

        <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field name="name" placeholder="Vorname" type="text"
                          v-model="name" required>
            </v-text-field>
            <v-text-field validate-on-blur name="email" placeholder="E-Mail-Adresse" type="email"
                          v-model="email" :rules="emailRules" required>
            </v-text-field>
            <v-text-field validate-on-blur name="password" placeholder="Passwort" id="password"
                          type="password" required v-model="password" :rules="passwordRules">
            </v-text-field>
        </v-form>

        <v-btn class="button button--primary" :disabled="!valid" @click="submit">Weiter</v-btn>      
    
    </div>
</template>
<script>
import firebase from 'firebase';

export default {
    name: 'Register',
    data() {
    return {
        valid: false,
        name: '',
        email: '',
        password: '',
        emailRules: [
            v => !!v || 'Eine Email-Adresse ist notwendig',
            v => /.+@.+/.test(v) || 'Die Adresse ist nicht im gültigen Format.'
        ],
        passwordRules: [
            v => !!v || 'Ein Passwort ist notwendig.',
            v =>
                v.length >= 6 ||
                'Das Passwort muss länger als 6 Zeichen sein.'
        ]
        };
    },
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.$store.dispatch('userRegister', {
                    name: this.name,
                    email: this.email,
                    password: this.password
                });
            }

            // FIXME: prüfen, ob nötig. Das push() auf "/welcome" wird bereits oben durch userRegister 
            // ausgeführt, klappt nur scheinbar manchmal nicht
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    // User is signed in.
                    this.$router.push("/welcome").catch( error => {
                        if ( error ) {
                            console.warn( `consumed router exception: ${error}` );
                        } else {
                            console.warn( `consumed unknown router exception ${new Error().stack.replace( /^[\s\S]+?at/, "at" )}` );
                        }
                    });
                }
            });
        }
    },
};
</script>